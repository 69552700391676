import React, { useContext, useState } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { DataContext } from "../context/DataContext";
import sendDocumentFeedback from "../networking/sendFeedback";
import ReactStars from "react-rating-stars-component";
import { FaStar } from "react-icons/fa";
import { usePopup } from '../context/PopupContext';

function FeedbackPopup({ onClose }) {
    const settings = useContext(SettingsContext);
    const data = useContext(DataContext);
    const { showPopup } = usePopup();

    const [feedback, setFeedback] = useState(""); // State for feedback text
    const [rating, setRating] = useState(0); // State for star rating

    const handleSubmitFeedback = () => {
        if (!feedback.trim()) { // Check if feedback is empty
            showPopup({
                title: "Missing Feedback",
                message: "Please enter your feedback before submitting.",
                variant: "default"
            });
            return; // Exit function if feedback is empty
        }
        
        showPopup({
            title: "Thank You!",
            message: "Your feedback has been submitted successfully.",
            variant: "success",
            autoClose: true,
            autoCloseDelay: 2000
        });
        
        settings.feedbackGiven = true;
        sendDocumentFeedback(data.documentHash, feedback, rating, showPopup); // Pass feedback and rating
        onClose();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-lg font-bold mb-4">Do you like this format?</h2>
                <p className="mb-6">We are happy to receive your feedback on how this job opportunity is presented here.</p>

                {/* Star Rating Component */}
                <div className="mb-4">
                    <ReactStars
                        count={5}
                        onChange={newRating => setRating(newRating)} // Update rating state
                        size={24}
                        activeColor="#ffd700"
                        emptyIcon={<FaStar color="#ccc" />}
                        filledIcon={<FaStar />}
                    />
                </div>
                
                {/* Feedback Text Area */}
                <textarea
                    rows="4"
                    value={feedback} // Controlled input
                    onChange={(e) => setFeedback(e.target.value)} // Update feedback state
                    className="w-full p-2 border border-gray-300 rounded-md mb-4"
                    placeholder="Your feedback here..."
                ></textarea>

                <div className="flex justify-end space-x-2">
                    <button onClick={onClose} className="px-4 py-2 bg-gray-200 rounded">
                        Cancel
                    </button>
                    <button className="px-4 py-2 bg-teal-600 text-white rounded" onClick={handleSubmitFeedback}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FeedbackPopup;
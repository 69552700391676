async function sendSignature(documentHash, feedback = "THIS IS A TEST", onSuccess, onError) {
    try {
    const formData = new FormData();
    formData.append('hash', documentHash);
    formData.append('action', 'sign');
    formData.append('feedback', feedback);

   await fetch(`https://api.keysearch.com/briefing/${documentHash}`, {
      method: 'POST',
      body: formData,
    });

    if (onSuccess) {
      onSuccess({
        title: "Success",
        message: "Your signature has been saved successfully!",
        variant: "success",
        autoClose: true,
        autoCloseDelay: 3000
      });
    }

  } catch (error) {
    console.error("Error signing", error);
    if (onError) {
      onError({
        title: "Error",
        message: "There was an error signing the document. Please try again.",
        variant: "default"
      });
    }
  }
  
}

export default sendSignature;
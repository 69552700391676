import React, { createContext, useContext, useState } from 'react';
import Popup from '../components/Popup';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [popup, setPopup] = useState(null);

  const showPopup = ({ title, message, buttonText, variant, autoClose, autoCloseDelay }) => {
    setPopup({
      title,
      message,
      buttonText,
      variant,
      autoClose,
      autoCloseDelay
    });
  };

  const hidePopup = () => {
    setPopup(null);
  };

  return (
    <PopupContext.Provider value={{ showPopup }}>
      {children}
      {popup && (
        <Popup
          isOpen={true}
          onClose={hidePopup}
          {...popup}
        />
      )}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
}; 
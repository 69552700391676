import React, { useState, useEffect, useContext } from 'react';
import { DataContext } from '../context/DataContext';
import { SettingsContext } from '../context/SettingsContext';
import getFromData from '../helperFunctions/getFromData';
import { FaFileUpload } from "react-icons/fa";
import { usePopup } from '../context/PopupContext';

function UploadableImage(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const data = useContext(DataContext);
  const settings = useContext(SettingsContext);
  const { showPopup } = usePopup();
  const dataKey = props.dataKey;
  const placeholderImage = 'https://via.placeholder.com/400x200';

  useEffect(() => {
    if (!selectedFile) {
      setPreviewUrl(null);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreviewUrl(objectUrl);

    // Cleanup
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      await validateAndUpload(data.documentHash, file);
    }
  };

  const validateAndUpload = async (documentHash, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('if', dataKey);

    try {
      const response = await fetch(`https://api.keysearch.com/briefing/${data.documentHash}`, {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      showPopup({
        title: "Success",
        message: "Your image has been uploaded successfully!",
        variant: "success",
        autoClose: true,
        autoCloseDelay: 3000
      });
    } catch (error) {
      console.error("Error uploading the file:", error);
      showPopup({
        title: "Upload Failed",
        message: "There was an error uploading your image. Please try again.",
        variant: "default"
      });
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block', ...props.style }}>
      <img 
        src={previewUrl || (getFromData(dataKey, data) !== '-' ? getFromData(dataKey,data) : null) || placeholderImage} 
        alt="Uploaded Preview" 
        className={`${props.className} object-cover`} 
        style={{ ...props.style, width: '100%', height: '100%' }}
      />
      { settings.editable &&
      <label style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>
        <div className='shadow-lg hover:scale-105 text-pink-400' style={{ width: '50px', height: '50px', background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius:"10%" }}>
          <FaFileUpload size={30} />
        </div>
        <input 
          type="file" 
          accept=".png" 
          onChange={handleFileChange} 
          style={{ display: 'none' }} 
        />
      </label> }
    </div>
  );
}

export default UploadableImage;

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import successAnimation from '../assets/animations/lottie_ok.json';
import warningAnimation from '../assets/animations/lottie_alert.json';
import infoAnimation from '../assets/animations/lottie_info.json';

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-in-out;
`;

const PopupContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  width: 90%;
  position: relative;
  animation: slideIn 0.3s ease-out;
`;

const PopupTitle = styled.h3`
  margin: 0 0 1rem 0;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

const PopupMessage = styled.p`
  margin: 0 0 1.5rem 0;
  color: #666;
  line-height: 1.5;
  text-align: center;
`;

const PopupButton = styled.button`
  background: ${props => {
    switch (props.variant) {
      case 'success':
        return '#4CAF50';
      case 'warning':
        return '#ff9800';
      case 'info':
      default:
        return '#00a3ad';
    }
  }};
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;

  &:hover {
    background: ${props => {
      switch (props.variant) {
        case 'success':
          return '#45a049';
        case 'warning':
          return '#f57c00';
        case 'info':
        default:
          return '#008c95';
      }
    }};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
  line-height: 1;
  
  &:hover {
    color: #333;
  }
`;

const AnimationContainer = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popup = ({ 
  isOpen, 
  onClose, 
  title, 
  message, 
  buttonText = 'OK', 
  variant = 'info',
  autoClose = false,
  autoCloseDelay = 3000
}) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (isOpen && autoClose) {
      timeoutRef.current = setTimeout(onClose, autoCloseDelay);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOpen, autoClose, autoCloseDelay, onClose]);

  const getAnimation = () => {
    switch (variant) {
      case 'success':
        return successAnimation;
      case 'warning':
        return warningAnimation;
      case 'info':
      default:
        return infoAnimation;
    }
  };

  if (!isOpen) return null;

  return (
    <PopupOverlay>
      <PopupContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <AnimationContainer>
          <Lottie 
            animationData={getAnimation()} 
            loop={variant === 'success' ? 1 : true}
            style={{ width: '100%', height: '100%' }}
          />
        </AnimationContainer>
        <PopupTitle>{title}</PopupTitle>
        <PopupMessage>{message}</PopupMessage>
        <PopupButton variant={variant} onClick={onClose}>
          {buttonText}
        </PopupButton>
      </PopupContent>
    </PopupOverlay>
  );
};

export default Popup; 
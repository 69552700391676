import { react } from "react";
import { useAuth } from "../context/AuthContext";
import { FaSignOutAlt, FaShare } from "react-icons/fa";
import { usePopup } from "../context/PopupContext";

function TopBar() {
  const { currentUser, logout } = useAuth();
  const { showPopup } = usePopup();

  const testPopups = () => {
    // Test success popup
    showPopup({
      title: "Success Message",
      message: "This is a success popup with auto-close enabled.",
      variant: "success",
      autoClose: true,
      autoCloseDelay: 3000
    });

    // Test warning popup after success
    setTimeout(() => {
      showPopup({
        title: "Warning Message",
        message: "This is a warning popup that needs manual closing.",
        variant: "warning"
      });
    }, 3500);

    // Test info popup after warning
    setTimeout(() => {
      showPopup({
        title: "Information",
        message: "This is an information popup that needs manual closing.",
        variant: "info"
      });
    }, 7000);
  };

  return (
    <div className="h-10 w-full flex bg-gray-900 text-white items-center px-6">
        { currentUser.photoURL && <img src={currentUser.photoURL} alt="profile" width={20} height={20} referrerPolicy="no-referrer" className="rounded-full mr-2" /> }
      You are logged in as {currentUser.displayName}

      {process.env.NODE_ENV === 'development' && (
        <button 
          className="ml-auto flex items-center bg-purple-600 px-2 py-0.5 rounded-md text-white hover:bg-purple-500 transition-all duration-200 ease-in-out"
          onClick={testPopups}
        >
          Test Popups
        </button>
      )}

      <button className="ml-4 flex items-center bg-gray-700 px-2 py-0.5 rounded-md text-white hover:bg-gray-500 transition-all duration-200 ease-in-out">
        <FaShare />
        &nbsp;&nbsp;Get Preview Link
      </button>

      <button
        className="flex items-center ml-4 bg-gray-700 px-2 py-0.5 rounded-md text-white hover:bg-gray-500 transition-all duration-200 ease-in-out"
        onClick={() => {
          logout();
        }}
      >
        <FaSignOutAlt />
        &nbsp;&nbsp;Log Out
      </button>
    </div>
  );
}

export default TopBar;

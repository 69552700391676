async function sendDocumentFeedback(documentHash, feedback, rating, onError) {
    try {
    const formData = new FormData();
    formData.append('hash', documentHash);
    formData.append('action', 'giveFeedback');
    formData.append('feedback', feedback);
    formData.append('rating', rating);

   let response = await fetch(`https://api.keysearch.com/briefing/${documentHash}`, {
      method: 'POST',
      body: formData,
    });

    // log the response to console

    console.log("response", response);
  } catch (error) {
    console.error("Error signing", error);
    if (onError) {
      onError({
        title: "Error",
        message: "There was an error submitting your feedback. Please try again.",
        variant: "default"
      });
    }
  }
  
}

export default sendDocumentFeedback;
import React from 'react';
import { auth } from '../firebase/firebaseConfig'; // Adjust the path as necessary
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { usePopup } from '../context/PopupContext';

export const LoginPage = () => {
    const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);
    const { showPopup } = usePopup();

    const handleSignIn = async () => {
        await signInWithGoogle();

        const currentUser = auth.currentUser;

        const token = await currentUser?.getIdToken();

        if (!token) {
            showPopup({
                title: "Login Failed",
                message: "We couldn't complete your login. Please try again.",
                variant: "default"
            });
            return;
        }

        const response = await fetch('https://api.keysearch.com/authorize-google-signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: token,
            }),
        });

        console.log(response);

        if (response.status === 403) {
            await auth.signOut();
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-xl font-semibold">Loading...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Error:</strong>
                    <span className="block sm:inline">{error.message}</span>
                </div>
            </div>
        );
    }

    if (user) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-xl font-semibold">Redirecting...</div>
            </div>
        );
    }

    return (
        <div className="flex justify-center items-center h-screen bg-gray-50">
            <div className="max-w-sm w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                <h2 className='text-center text-2xl font-thin text-gray-900 mb-6'>Login Below</h2>
                <button 
                    onClick={handleSignIn} 
                    className="bg-teal-600 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Sign in with Google
                </button>
            </div>
        </div>
    );
};

export default LoginPage;

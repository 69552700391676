async function sendUpdate(documentHash, key, value, token, onError) {

  if (!token) {
    console.error("Token missing");
    return;
  } else {
    console.log("Token present: ", token);
  }

 
  console.log('sending...');
    try {
    const formData = new FormData();
    formData.append('hash', documentHash);
    formData.append('f', key);
    formData.append('v', value);

    
    console.log(value, typeof value);

   const response = await fetch(`https://api.keysearch.com/briefing/${documentHash}`, {
      method: 'PATCH',
      action: 'update',
      headers: { authorization : 'Bearer ' + token },
      body: formData,
    });

   // console.log(response);

  } catch (error) {
    console.error("Error updating", error);
    if (onError) {
      onError({
        title: "Update Failed",
        message: "There was an error updating the document. Please try again.",
        variant: "default"
      });
    }
    return 'Error';
  }

  return 'Success';

}

export default sendUpdate;
import react, { useContext, useState, useRef } from "react";
import { DataContext } from "../context/DataContext";
import getFromData from "../helperFunctions/getFromData";
import { SettingsContext } from "../context/SettingsContext";
import sendUpdate from "../networking/sendUpdate";
import { FaArrowUp } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import { usePopup } from '../context/PopupContext';

const EditablePinkButton = (props) => {
  const data = useContext(DataContext);
  const settings = useContext(SettingsContext);
  const textFieldRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [linkPreview, setLinkPreview] = useState("");
  const { currentUser, loading } = useAuth();
  const { showPopup } = usePopup();

  async function handleOkButtonClick() {
    if (!textFieldRef.current.value.includes("http")) { 
        showPopup({
            title: "Invalid URL",
            message: "Please enter a valid URL (needs to start with https://)",
            variant: "default"
        });
        return;
    }
    
    const token = await currentUser.getIdToken();
    sendUpdate(data.documentHash, props.dataKey, textFieldRef.current.value, token, showPopup);
    setLinkPreview(textFieldRef.current.value);
    setIsEditing(false);
  }

  return (

   (!settings.editable && getFromData(props.dataKey, data) === "") ? <div /> :

    <div className={`${props.className} flex flex-col`}>
      <div className="flex w-full justify-center">
        {" "}
        <button
        disabled={isEditing}
          onClick={() => {
            window.open(
              props.hardcodedUrl || getFromData(props.dataKey, data),
              "_blank",
              "noopener noreferrer"
            );
          }}
          className={`pinkButton ${isEditing ? 'bg-gray-500' : 'bg-pink-500'}`}
        >
          {props.text}
        </button>
        {settings.editable && !isEditing && (props.hardcodedUrl === undefined) && (
          <button
            onClick={() => {
              setIsEditing(true);
            }}
            className="text-white ml-8"
          >
            Edit link
          </button>
        )}
      </div>
      {isEditing && (
            <>
      <FaArrowUp className="text-white mx-auto my-5" />

      <div className="flex w-96 mx-auto">
        <input
          defaultValue={linkPreview || getFromData(props.dataKey, data)}
          type="text"
          ref={textFieldRef}
          placeholder="Add a url for the link"
          className="w-3/4 h-12 bg-white rounded-l-lg p-4"
        />
        <button
          onClick={handleOkButtonClick}
          className={`w-1/4 h-12 bg-pink-500 rounded-r-lg flex text-white items-center justify-center`}
        >
          Save Link
        </button>
      </div>
    </> )}
    </div>
  );
};

export default EditablePinkButton;

import React, { useState } from "react";
import SpotifyIcon from "../assets/spotify.png";
import WebsitePicture from "../assets/small_website.png";
import LinkedinPicture from "../assets/small_linkedin.png";
import YouTubePicture from "../assets/small_youtube.png";
import { FaTrash } from "react-icons/fa";
import getFromData from "../helperFunctions/getFromData";
import { DataContext } from "../context/DataContext";
import { SettingsContext } from "../context/SettingsContext";
import { usePopup } from '../context/PopupContext';
import expireBriefDoc from '../networking/expireBriefDoc';

const socialIcons = [
  { icon: SpotifyIcon, link: "https://open.spotify.com/show/35otzP8zMdhoYFMno2a23A", alt: "Our Spotify" },
  {
    icon: WebsitePicture,
    link: "https://www.keysearch.com",
    alt: "Our Website",
  },
  {
    icon: LinkedinPicture,
    link: "https://www.linkedin.com/company/keysearch/",
    alt: "Our linkedin profile",
  },
  {
    icon: YouTubePicture,
    link: "https://www.youtube.com/@KeySearch-Executive-Search",
    alt: "Our YouTube channel",
  },
];

function HowToProceed(props) {
  const [choiceState, setChoiceState] = useState("howToProceed");
  const [cvFile, setCvFile] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState(false);
  const data = React.useContext(DataContext);
  const settings = React.useContext(SettingsContext);
  const { showPopup } = usePopup();

  const truncateFileName = (fileName) => {
    if (fileName.length > 20) {
        const truncatedFileName = `${fileName.substr(0, 10)}...${fileName.substr(
            fileName.length - 10
        )}`;
        return truncatedFileName;
        } else {
        return fileName;
        }
  }

  const handleFileChange = (event) => {
    setCvFile(event.target.files[0]);

    const file = event.target.files[0];
    if (file) {
      setCvFile(file);
      setFileName(file.name || '');
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const validateAndUpload = async (documentHash) => {
    if (!cvFile) {
      showPopup({
        title: "Missing CV",
        message: "Please upload your CV before proceeding.",
        variant: "default"
      });
      return;
    }
  
    if (feedback.length < 10) {
      showPopup({
        title: "Missing Feedback",
        message: "Please provide feedback (at least 10 characters) before proceeding.",
        variant: "default"
      });
      return;
    }
  
    setIsUploading(true);
  
    const formData = new FormData();
    formData.append('file', cvFile);
    formData.append('feedback', feedback);
    formData.append('action', 'upload');
  
    try {
      const response = await fetch(`https://api.keysearch.com/briefing/${documentHash}`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      showPopup({
        title: "Success",
        message: "Your CV and feedback have been uploaded successfully!",
        variant: "success",
        autoClose: true,
        autoCloseDelay: 3000
      });
      setChoiceState("uploadSuccess");
    } catch (error) {
      console.error("Error uploading feedback and CV:", error);
      showPopup({
        title: "Upload Failed",
        message: "There was an error uploading your CV and feedback. Please try again.",
        variant: "default"
      });
    } finally {
      setIsUploading(false);
    }
  };

  async function validateAndExpire(documentHash) {
    if (feedback.length < 10) {
      showPopup({
        title: "Missing Feedback",
        message: "Please provide feedback (at least 10 characters) before proceeding.",
        variant: "default"
      });
      return;
    } else {  
      try {
        await expireBriefDoc(
          documentHash,
          feedback,
          (popupConfig) => {
            showPopup(popupConfig);
            setChoiceState("notInterestedConfirmed");
          },
          showPopup
        );
      } catch (error) {
        console.error("Error expiring document:", error);
      }
    }
  }

  if (choiceState === "howToProceed" && props.accepted ==="empty")  {
    return (
      <section className="lightgrey-bg flex w-full p-8 flex-col py-16 md:p-20 ">
        <div className="flex flex-col">
          <p className="font-bold pb-4">HOW TO PROCEED</p>
          <p className="font-light pb-4">
            Please click one of the buttons below. Should you be interested, we will ask you to send us your CV.
          </p>
          {props.howToProceed}
        </div>

        <div className="flex flex-col md:flex-row md:gap-4">
          <div className="flex flex-col" style={{ flex: 0.6 }}>
            <button
              className="light-turquoise-bg py-5 px-6 text-white rounded-lg shadow-lg hover:scale-105 mb-3"
              onClick={() => setChoiceState("interested")}
            > 
            { !settings.confidential ?
             `I am interested in becoming ${getFromData("OpportunityTitle", data)} at ${ getFromData("ClientName", data)}` : 
              `I am interested in this opportunity.` }
            
            </button>

            <button
              className="dark-turquoise-bg py-5 px-6 text-white rounded-lg shadow-lg hover:scale-105"
              onClick={() => setChoiceState("notInterested")}
            >
              I am not interested at this time.
            </button>
          </div>
          <div className="flex flex-col flex-1">
            <p className=" text-sm mt-8 md:mt-0">
              You will have the opportunity to provide feedback if you are not
              interested.
              <br />
              <br />
              <span className="dark-turquoise-text">
                We are committed to protecting and respecting your privacy. The
                data that you provide to us will be processed solely in
                connection with the recruitment process. Key Search does not
                share data with any third parties. We are happy to answer any
                questions you might have regarding how we process and store your
                data.
              </span>
            </p>
            <div className="flex mt-10 ml-0 md:mt-auto justify-center md:justify-normal gap-4 items-center">
              {socialIcons.map((item, index) => (
                <div key={`socialIcon-${index}`}>
                  <button
                    className="ml-3 hover:scale-110"
                    onClick={() => window.open(item.link, "_blank")}
                  >
                    <img
                      src={item.icon}
                      alt={item.alt}
                      className="h-5 w-5 object-contain"
                      style={{ filter: "invert(1) brightness(0.2)" }}
                      
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  } else if (choiceState === "notInterested") {
    return (
      <section className="lightgrey-bg flex w-full md:p-20 p-8 flex-col">
        <p className="font-bold pb-1">ARE YOU SURE?</p>
        <p className="text-sm pb-4">
          Please tell us why are you not interested in this opportunity.
        </p>

        <textarea
          className="border-2 border-black rounded-lg p-2 w-full h-32 mt-8 mb-3 md:mt-0 md:mb-0"
          placeholder="Please provide feedback here"
          value={feedback}
          onChange={handleFeedbackChange}
        ></textarea>
        <div className="flex gap-2 md:gap-5 flex-col md:flex-row">
          <button
            className="flex-1 dark-turquoise-bg py-5 px-6 text-white rounded-lg shadow-lg hover:scale-105 mt-3"
            onClick={() =>  validateAndExpire(props.documentHash)}
            disabled={isUploading}
          >
            I'm sure. Save the feedback and expire this document.
          </button>

          <button
            className=" flex-1 bg-gray-500 py-5 px-6 text-white rounded-lg shadow-lg hover:scale-105 mt-3"
            onClick={() => setChoiceState("howToProceed")}
          >
            I changed my mind. I am interested!
          </button>
        </div>
      </section>
    );
  } else if (choiceState === "interested") {
    return (
      <section className="lightgrey-bg flex w-full md:p-20 p-8 flex-col">
        <p className="font-bold pb-1 text-center text-gray-800 text-2xl">
          That's great to hear!
        </p>
        <p className="text-sm p-6 text-center">
          To proceed, please provide feedback and attach your CV. Following this, we will be in touch with you shortly to discuss the next steps.
        </p>
        <textarea
          className="border-2 border-black rounded-lg p-2 w-full h-32"
          placeholder="Please provide feedback here"
          value={feedback}
          onChange={handleFeedbackChange}
        ></textarea>

<div className="mx-auto mt-6 text-center">
  {/* Hidden file input */}
  <input
    id="fileInput"
    type="file"
    accept=".pdf"
    onChange={handleFileChange}
    disabled={isUploading}
    className="opacity-0 absolute w-0 h-0"
  />

  {/* Custom styled element that triggers the file input */}
  <label
    htmlFor="fileInput"
    className={`inline-block light-turquoise-bg text-white font-bold py-2 mb-4 shadow-lg px-4 rounded-lg cursor-pointer hover:dark-turquoise-bg hover:scale-105 ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
    disabled={isUploading}
  >
    {isUploading ? 'Uploading...' : 'Attach CV'}
  </label>
</div>

        {cvFile && (
          <div className="flex items-center justify-center">
            <p className="text-center my-10">File <span className="text-gray-500">{truncateFileName(fileName)}</span> ready to upload.</p>

            <button className="ml-2" onClick={() => setCvFile(null)}>
              <FaTrash className="text-red-500" />
            </button>
          </div>
        )}

        <button
          className={`${!isUploading ? "dark-turquoise-bg" : "lightgrey-bg"} py-5 px-6 ${!isUploading ? "text-white" : "text-gray-800"} rounded-lg shadow-lg hover:scale-101 mt-3 ${
            isUploading ? "bg-gray-500" : ""
          }`}
          onClick= {isUploading ? null : () => validateAndUpload(props.documentHash)}
          disabled={isUploading}
        >
        <div className="flex items-center justify-center font-bold text-xl">
            {isUploading ? (
                <div className="spinner mr-4"></div>
            ) : (
                <div className="mr-4"></div>
            )}
            {isUploading ? "Uploading..." : "Save and send"}
        </div>
        </button>
      </section>
    );
  } else if (choiceState === "notInterestedConfirmed" || props.accepted === false) {
    return (
      <section className="lightgrey-bg flex w-full p-20 flex-col">
        <p className="font-bold pb-1 text-center">
          Thank you. We hope to stay in touch for future opportunities.
        </p>
        <p className="text-center pb-4">This document will expire shortly.</p>

        <p className="text-center">
          Should you change your mind, please contact us via{" "}
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              className="dark-turquoise-text"
              href="https://keysearch.com"
            >
              our website
            </a>
          </span>
          .
        </p>
      </section>
    );
  } else if (choiceState === "uploadSuccess" || props.accepted === true) {
    return (
      <section className="lightgrey-bg flex w-full p-20 flex-col">
        <p className="font-bold pb-4 text-center text-lg">
          Thank you for your interest in this opportunity.
        </p>
        <p className="text-center pb-4">
          Your feedback and CV have been successfully sent.
        </p>

        <p className="text-center">
          We will be in touch with you shortly to discuss the next steps.
        </p>
      </section>
    );
  }
}

export default HowToProceed;

async function expireBriefDoc(documentHash, feedback = "THIS IS A TEST", onSuccess, onError) {
  try {
    const formData = new FormData();
    formData.append('feedback', feedback);
    formData.append('action', 'expire');

    await fetch(`https://api.keysearch.com/briefing/${documentHash}`, {
      method: 'POST',
      body: formData,
    });

    if (onSuccess) {
      onSuccess({
        title: "Success",
        message: "Your feedback has been saved and the document has been expired.",
        variant: "success",
        autoClose: true,
        autoCloseDelay: 3000
      });
    }

  } catch (error) {
    console.error("Error expiring document:", error);
    if (onError) {
      onError({
        title: "Error",
        message: "There was an error saving your feedback. Please try again.",
        variant: "default"
      });
    }
  }
}

export default expireBriefDoc;